import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Link } from "gatsby"

import MainContentWrap from "../Wrappers/MainContentWrap"
import HomeMainAnimation from "./HomeMainAnimation"
import arrow from "../../images/icons/utils/arrow.svg"
import styled from "styled-components"

const HomeIntroContentWrap = styled.div`
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export default function HomeIntro() {
  const { t } = useTranslation()
  return (
    <div style={{ height: "100vh" }}>
      <HomeMainAnimation />
      <HomeIntroContentWrap>
        <MainContentWrap>
          <h1 className="fadeup" id="main-page--title">
            {t("title1")} WebBoxes. <br />
            {t("title2")} <br />
            {t("title3")}
          </h1>
          <Link to="/about/" className="arrowlink fadeup fadeup-2">
            {t("aboutUsLink")}
            <img src={arrow} alt="arrow" />
          </Link>
        </MainContentWrap>
      </HomeIntroContentWrap>
    </div>
  )
}
