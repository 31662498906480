import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Slider from "react-slick"
import styled from "styled-components"
import values from "../../utils/values"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const SliderWrap = styled.div`
  width: 95%;
  margin: 0 auto;

  .slick-track {
    display: flex;
    height: 100%;

    .slick-slide {
      flex: 1 0 auto;
      height: auto;

      & > div:first-child {
        display: flex;
        height: 100%;
      }
    }
  }

  .slick-arrow {
    z-index: 3;
    width: 40px;
    height: 40px;

    :before {
      color: black;
      font-size: 40px;
    }

    &.slick-next {
      right: -10px;
    }

    &.slick-prev {
      left: -10px;
    }
  }
`

const StyledItemContainer = styled.div`
  display: flex !important;
  justify-content: center;
  height: 100%;
  padding: 0.5rem;

  .gatsby-image-wrapper {
    border: 0.2rem solid black;
    border-radius: 0.5rem;
    max-width: 80%;
  }

  & > * {
    flex-grow: 1;
  }

  @media (min-width: ${values.DEVICESIZE.tablet}) {
    padding: 1rem;

    .gatsby-image-wrapper {
      border: 1rem solid black;
      border-radius: 1.5rem;
    }
  }
`

export default function SliderSelectedWorks() {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "gallery/works" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid(maxWidth: 500, quality: 75) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <SliderWrap>
      <Slider {...settings}>
        {data.allFile.edges.map(image => (
          <StyledItemContainer key={image.node.base.split(".")[0]}>
            <Img
              fluid={image.node.childImageSharp.fluid}
              alt={image.node.base.split(".")[0]}
            />
          </StyledItemContainer>
        ))}
      </Slider>
    </SliderWrap>
  )
}
