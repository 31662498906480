import React from "react"
import {graphql} from 'gatsby';
import { useTranslation} from 'gatsby-plugin-react-i18next';

import SEO from "../components/seo"
import ColorThemeWrapper from "../components/Wrappers/ColorThemeWrapper"

import HomeIntro from "../components/Home/HomeIntro"
import HomeSelectedWorks from "../components/Home/HomeSelectedWorks"
import HomeOurService from "../components/Home/HomeOurService"
import HomeClientsAndPartners from "../components/Home/HomeClientsAndPartners"

const IndexPage = () => {
  const {t} = useTranslation();
  return (
    <>
      <SEO title={t('seoTitle')} />
      <ColorThemeWrapper
        wrap1={<HomeIntro />}
        wrap2={
          <>
            <HomeOurService />
            <HomeSelectedWorks />
          </>
        }
        wrap3={<HomeClientsAndPartners />}
      />
    </>
  )
}

export default IndexPage

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: {ns: {in: ["common", "index"]}, language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;