import React from "react"
import { Link } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import MainContentWrap from "../Wrappers/MainContentWrap"
import SliderSelectedWorks from "../SingleComponents/SliderSelectedWorks"
import arrow from "../../images/icons/utils/arrow.svg"

export default function HomeSelectedWorks() {
  const { t } = useTranslation()
  return (
    <div style={{ margin: `15rem 0` }}>
      <MainContentWrap>
        <div className="wrapper-center">
          <h2 className="fadeup">{t("worksSecTitle")}</h2>
          <Link to="/works/" className="arrowlink fadeup fadeup-2">
            {t("worksSecLink")}
            <img src={arrow} alt="arrow" />
          </Link>
        </div>
      </MainContentWrap>

      <SliderSelectedWorks />
    </div>
  )
}
