import React from "react"
import { Link } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import MainContentWrap from "../Wrappers/MainContentWrap"
import ClientsLogoBoard from "../SingleComponents/ClientsLogoBoard"

import arrowWhite from "../../images/icons/utils/arrowWhite.svg"
import styled from "styled-components"

const HomeClientsAndPartnersWrap = styled.div`
  padding-top: 15rem;
  padding-bottom: 15rem;

  .white-line {
    background: white;
  }

  .homePartners-title {
    margin-bottom: 15rem;
  }
`

export default function HomeClientsAndPartners() {
  const { t } = useTranslation()
  return (
    <MainContentWrap>
      <HomeClientsAndPartnersWrap>
        {/* DESCRIPTION / TITLE SECTION */}
        <div className="homePartners-title para-para">
          <h2 className="fadeup">
            {t("clientsSecTitle1")}
            <br />
            {t("clientsSecTitle2")}
            <br />
            {t("clientsSecTitle3")}
          </h2>
          <Link to="/contacts/" className="arrowlink fadeup fadeup-2">
            {t("clientsSecLink")}
            <img src={arrowWhite} alt="arrow" />
          </Link>
        </div>

        {/* CLIENTS & PARTNERS LOGOS and Industries  SECTION*/}
        <div className="wrapper-center">
          <h2 className="fadeup">{t("clientsSecSecondaryTitle")}</h2>
          <div className="fadeup fadeup-2 sub">
            {t("clientsSecDescription")}
          </div>
        </div>

        <ClientsLogoBoard />
      </HomeClientsAndPartnersWrap>
    </MainContentWrap>
  )
}
