import React from "react"
import { Link } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import MainContentWrap from "../Wrappers/MainContentWrap"
import MainServiceBanner from "../../images/illustrations/wihout_logo_abstract_cubes_illustrations/far_v2_abstract_cube_no_logo_wbx.jpg"
import arrow from "../../images/icons/utils/arrow.svg"
import styled from "styled-components"

const HomeService = styled.div`
  height: 100vh;
  width: 100%;
  //overflow: hidden;
`

const HomeServiceContentWrap = styled.div`
  position: absolute;
  width: 100%;
  left: 50%;
  top: 25%;
  transform: translate(-50%, -50%);
  overflow: hidden;
`

const Banner = styled.div`
  height: 100%;
  opacity: 0.45;

  background-image: url(${MainServiceBanner});
  background-repeat: repeat-x;
  animation: cloudwash 600s linear infinite;

  @keyframes cloudwash {
    from {
      background-position: 0%;
    }
    to {
      background-position: 1000%;
    }
  }
`

export default function HomeOurService() {
  const { t } = useTranslation()
  return (
    <HomeService>
      <Banner />
      <HomeServiceContentWrap>
        <MainContentWrap>
          <h2 className="fadeup">
            {t("serviceSecTitle1")}
            <br /> {t("serviceSecTitle2")} <br />
            {t("serviceSecTitle3")}
          </h2>
          <Link to="/service/" className="arrowlink fadeup fadeup-2">
            {t("serviceSecLink")}
            <img src={arrow} alt="arrow" />
          </Link>
        </MainContentWrap>
      </HomeServiceContentWrap>
    </HomeService>
  )
}
