import React from "react"
import homeMainAnimation from "../../utils/functions/homeMainAnimation"
import "./HomeMainAnimation.css"

class HomeMainAnimation extends React.Component {
  constructor(props) {
    super(props)
    this.cubeRef = React.createRef()
  }

  componentDidMount() {
    const mainAnime = homeMainAnimation(this.cubeRef.current) //anime.js works without ref, ref is needed to change cube's size according to @media
    mainAnime.play()
  }

  render() {
    return (
      <div className="main-page-animation">
        <div className="cube-wrapper one">
          <div className="cube" ref={this.cubeRef}>
            <div className="side front"></div>
            <div className="side back"></div>
            <div className="side right"></div>
            <div className="side left"></div>
            <div className="side top"></div>
            <div className="side bottom"></div>
          </div>
        </div>
        <div className="cube-wrapper two">
          <div className="cube">
            <div className="side front"></div>
            <div className="side back"></div>
            <div className="side right"></div>
            <div className="side left"></div>
            <div className="side top"></div>
            <div className="side bottom"></div>
          </div>
        </div>
        <div className="cube-wrapper three">
          <div className="cube">
            <div className="side front"></div>
            <div className="side back"></div>
            <div className="side right"></div>
            <div className="side left"></div>
            <div className="side top"></div>
            <div className="side bottom"></div>
          </div>
        </div>
        <div className="cube-wrapper four">
          <div className="cube">
            <div className="side front"></div>
            <div className="side back"></div>
            <div className="side right"></div>
            <div className="side left"></div>
            <div className="side top"></div>
            <div className="side bottom"></div>
          </div>
        </div>
        <div className="cube-wrapper five">
          <div className="cube">
            <div className="side front"></div>
            <div className="side back"></div>
            <div className="side right"></div>
            <div className="side left"></div>
            <div className="side top"></div>
            <div className="side bottom"></div>
          </div>
        </div>
      </div>
    )
  }
}

export default HomeMainAnimation
